// import React from 'react';
// import TextField from '@mui/material/TextField';
// import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
    Avatar, Box, Button, Card,
    Container, Divider, List, ListItem,
    ListItemAvatar, ListItemButton, ListItemText,
    Modal, TextField, Typography, MenuItem
} from '@mui/material';
import logo from '../../image/imgg.jpg'
import React, { Component } from 'react'
import * as XLSX from 'xlsx';
// import { Button, Container } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';



const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: '15em',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',

    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            }
        },
    },
}));

export default class User extends Component {

    constructor(props) {
        super(props);
        // console.log(this.props);
        this.state = {
            openmodal: false,
            img: "",
            display: false,
            openmodalll: false,
            item: 0,
            list: [
                {
                    orgName: "category1",
                    gstinNo: "hh",
                    Email: "category@gmail.com",
                },
                {
                    orgName: "category2",
                    gstinNo: "hh",
                    Email: "category2@gmail.com",
                },
                {
                    orgName: "category3",
                    gstinNo: "hh",
                    Email: "category3@gmail.com",
                },
            ],
            cat_name: "",
            cat_desc: "",
            cat_id: "",
            user_name: "",
            user_email: "",
            user_mobile: "",
            category: "",
            selectedFile: "",
            mailData: [],
        }
    }









    componentDidMount() {
        console.log(this.props.mail.user)
        this.props.viewAllCategory(this.props.login.org_id)
        this.props.viewUser(this.props.login.org_id)
    }
    handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            this.parseExcelData(file);
        }
    };
    parseExcelData = (file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0]; // Assuming you're interested in the first sheet
            const sheet = workbook.Sheets[sheetName];
            const excelData = XLSX.utils.sheet_to_json(sheet);
            console.log(excelData)
            const mailArray = excelData.map((item) => ({ name: item.NAME, email: item.EMAIL, phone: item.PHONE, category: this.state.category, org_id: this.props.login.org_id }));
            this.setState({ mailData: mailArray });
            console.log(mailArray)
        };

        reader.readAsBinaryString(file);
    };
    handleFileChange = (event) => {
        const file = event.target.files[0];
        this.setState({ selectedFile: file });
    };
    render() {
        const { Email, gstinNo, orgName, openmodalll, openmodal, display, item, img } = this.state

        return (
            <Container style={{
                marginTop: "6em",
            }} maxWidth="lg">

                <Box style={{
                    // flexDirection: "row",
                    display: "flex",
                    flexDirection: "row-reverse",
                    justifyContent: "space-evenly"
                }}>

                    <div style={{

                        alignItems: "flex-start",
                        flexDirection: "column",
                        display: "flex",
                        // gap: "2em"

                    }} >

                        <Box style={{
                            width: "100%", alignItems: "flex-start",
                            flexDirection: "column",
                            padding: "2em",
                            display: "flex",
                            // paddingTop: "1em",
                        }}>
                            {/* <Box > */}
                            <Search>
                                <SearchIconWrapper>
                                    <SearchIcon />
                                </SearchIconWrapper>
                                <StyledInputBase
                                    placeholder="Search…"
                                    inputProps={{ 'aria-label': 'search' }}
                                />
                            </Search>

                            {/* <Divider /> */}
                            <nav style={{
                                color: "black",
                                overflowY: "auto",
                                // maxHeight: "100%"

                            }} aria-label="secondary mailbox folders">





                                <List style={{
                                    MinWidth: "37em",
                                    maxHeight: "27em"
                                }} >
                                    {Array.isArray(this.props.mail.user) &&
                                        this.props.mail.user.map((i, index) => (


                                            <ListItem
                                                key={index}
                                                onClick={() => {
                                                    this.setState({ openmodalll: !openmodalll, user_d: i._id })
                                                    this.setState({ item: index })

                                                }}
                                                sx={{
                                                    cursor: "pointer",
                                                    borderBottom: "1px solid gray",
                                                }}>
                                                <ListItemAvatar >
                                                    <Avatar sx={{
                                                        backgroundColor: "transparent",
                                                        color: "black"
                                                    }}>
                                                        {index + Number(1)}
                                                        {/* <ImageIcon /> */}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={i?.name?.length == 0 ? "N/A" : i?.name} secondary={i?.email?.length == 0 ? "N/A" : i.email} />
                                            </ListItem>


                                        ))
                                    }
                                </List>
                            </nav>

                            <Button sx={{
                                marginBottom: "3em"
                            }} onClick={() => {
                                // this.setState({ openmodal: true })
                                this.setState({ display: true })
                            }

                            } style={{
                                backgroundColor: "darkslategrey",

                                margin: "1em"

                            }} variant="contained">+ Create</Button>

                            {/* </Box> */}


                        </Box>

                        <Modal
                            open={this.state.openmodalll}

                        >

                            <Box
                                sx={
                                    {
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        width: 400,
                                        display: "flex",
                                        // Width:"100%",
                                        gap: 3,
                                        flexDirection: "column",

                                        bgcolor: 'background.paper',
                                        border: '2px solid #000',
                                        boxShadow: 24,
                                        color: "black",
                                        p: 4,
                                    }
                                }
                            >

                                <Typography color="black" sx={{
                                    display: "flex",
                                    gap: "1em",
                                    alignItems: "center"
                                }} variant="body1" component="h2">
                                    <Avatar>{item}</Avatar>
                                    {
                                        this.state.cat_name
                                    }

                                </Typography>


                                <Box>

                                    <Button sx={{ mt: 2 }} onClick={() => {

                                        // this.setState({openmodal:false})
                                        // this.state.list.push({Email,gstinNo,orgName});
                                        this.setState({
                                            openmodalll: false,

                                        })
                                        console.log(this.state.list);

                                    }
                                    } variant="contained">close</Button>
                                    <Button sx={{ mt: 2 }} onClick={() => {

                                        // this.setState({openmodal:false})
                                        // this.state.list.push({Email,gstinNo,orgName});
                                        this.setState({
                                            openmodalll: false,

                                        })

                                        item == this?.state?.list?.length ? this?.state?.list?.splice(-1) : this?.state?.list?.splice(item, 1)
                                        this.props.deleteUser(this.props.login.org_id, this.state.cat_id)
                                    }
                                    } variant="text"
                                    >Delete</Button>
                                </Box>

                            </Box>




                        </Modal>




                        <Modal

                            onClose={() => this.setState({ openmodal: false })}
                            open={this.state.openmodal}
                            //   onClose={()=>this.setState({open:false})}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >

                            <Grid container style={{
                                padding: "1em",
                                backgroundColor: "aliceblue",
                                width: "47em",
                                position: "absolute",
                                transform: "translate(-50%, -50%)",
                                left: " 50%",
                                top: "50%",
                            }} >

                                <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        label="Enter User Name"
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        label="Enter Email"
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="name"
                                        label="Enter"
                                        variant="standard"
                                        fullWidth
                                    />
                                </Grid>
                                <Button sx={{
                                    margin: "23px"
                                }} style={{
                                    backgroundColor: "darkslategrey"
                                }} variant="contained" color="primary" type="submit">
                                    Submit
                                </Button>

                            </Grid>

                        </Modal>
                    </div>

                    <div style={{
                        display: display ? `none` : `block`,
                        width: '50%'
                    }}>
                        <Typography sx={{
                            padding: "1em"
                        }} variant="h6" gutterBottom>
                            Subscriber
                        </Typography>
                        <img style={{
                            width: "100%", height: 'auto'
                        }} src={logo} />
                    </div>





                    <Box
                        style={{
                            margin: "20px",
                            display: display ? `block` : `none`
                        }}

                        // onClose={()=>  this.setState({ openmodal: false })}
                        // open={this.state.openmodal}
                        //   onClose={()=>this.setState({open:false})}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        {/* ================================================================================== */}

                        <Tabs style={{
                            width: "30em"
                        }}>
                            <TabList style={{
                                display: "flex",
                                justifyContent: "space-around",
                                listStyle: "none",
                                margin: 0,
                                cursor: "pointer",
                                paddingLeft: "0px"
                            }}>
                                <Tab onClick={() => {
                                    this.setState({ type: "individual" })
                                }}> Single user</Tab>
                                <Tab
                                    onClick={() => {
                                        this.setState({ type: "category" })
                                    }}
                                > UPLOAD from excel</Tab>
                            </TabList>

                            <TabPanel style={{
                                display: "flex",
                                // padding:".5em",
                                flexDirection: "column",
                                alignContent: "center",
                                // alignItems: "center",
                            }}>
                                <Grid container style={{
                                    padding: "1em",
                                    backgroundColor: "aliceblue",
                                    boxShadow: " inset 0px 0px 3px 0px",
                                }} >

                                    <Grid item xs={12}>
                                        <TextField
                                            id="name"
                                            label="Select Category"
                                            variant="standard"
                                            fullWidth
                                            select
                                            onChange={(e) => {
                                                this.setState({
                                                    category: e.target.value
                                                })
                                            }}
                                        >{this.props.mail.category.map((category, index) => (
                                            <MenuItem key={index} value={category._id}>
                                                {category.name}
                                            </MenuItem>
                                        ))}</TextField>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
                                        <TextField
                                            id="name"
                                            label="Enter User Name"
                                            variant="standard"
                                            fullWidth
                                            disabled={this.state.selectedFile !== ""}
                                            onChange={(e) => {
                                                this.setState({
                                                    user_name: e.target.value
                                                })
                                            }}
                                        />
                                        {/*  <div>

                               <input type="file" id="upload" hidden accept=".xls, .xlsx" onChange={this.handleFileUpload} />
                                    <label style={{
                                        display: "inline-block",
                                        backgroundColor: "darkslategrey",
                                        color: "white",
                                        padding: " 0.5rem",
                                        //  width: "59px",
                                        fontSize: "8px",
                                        fontFamily: "sans-serif",
                                        borderRadius: " 0.3rem",
                                        cursor: "pointer",
                                        //  marginTop: "1rem",
                                    }} for="upload"><CloudUploadIcon /></label>
                                </div> */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="name"
                                            label="Enter Email"
                                            variant="standard"
                                            fullWidth
                                            onChange={(e) => {
                                                this.setState({
                                                    user_email: e.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="name"
                                            label="Enter User Mobile"
                                            variant="standard"
                                            fullWidth
                                            onChange={(e) => {
                                                this.setState({
                                                    user_mobile: e.target.value
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Button sx={{
                                        margin: "23px"
                                    }} style={{
                                        backgroundColor: "darkslategrey"
                                    }} variant="contained" color="primary" type="submit"
                                        onClick={(e) => {
                                            this.setState({ display: false, user_name: "", user_email: "", user_mobile: "", category: "" })
                                            this.props.addUser(this.props.login.org_id, this.state.user_name, this.state.user_email, this.state.user_mobile, this.state.category)
                                        }}
                                    >
                                        Submit
                                    </Button>
                                    <Button sx={{
                                        margin: "23px"

                                    }}
                                        onClick={() => {
                                            this.setState({
                                                display: false,

                                            })
                                        }}


                                        variant="text" color="primary" type="submit">
                                        Cancel
                                    </Button>
                                </Grid>
                            </TabPanel>




                            <TabPanel style={{
                                display: "flex",
                                flexDirection: "column",
                                alignContent: "center",
                                // alignItems: "center",
                            }}>
                                <Grid container style={{
                                    padding: "1em",
                                    backgroundColor: "aliceblue",
                                    boxShadow: " inset 0px 0px 3px 0px",
                                }} >

                                    <Grid item xs={12}>
                                        <TextField
                                            id="name"
                                            label="Select Category"
                                            variant="standard"
                                            fullWidth
                                            select
                                            onChange={(e) => {
                                                this.setState({
                                                    category: e.target.value
                                                })
                                            }}
                                        >{this.props.mail.category.map((category, index) => (
                                            <MenuItem key={index} value={category._id}>
                                                {category.name}
                                            </MenuItem>
                                        ))}</TextField>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>

                                        <div>

                                            <input type="file" id="upload" hidden accept=".xls, .xlsx" onChange={this.handleFileUpload} />
                                            <label style={{
                                                display: "inline-block",
                                                backgroundColor: "darkslategrey",
                                                color: "white",
                                                padding: " 0.5rem",
                                                //  width: "59px",
                                                fontSize: "8px",
                                                fontFamily: "sans-serif",
                                                borderRadius: " 0.3rem",
                                                cursor: "pointer",
                                                //  marginTop: "1rem",
                                            }} for="upload"><CloudUploadIcon /></label>
                                        </div>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                <TextField
                                    id="name"
                                    label="Enter Email"
                                    variant="standard"
                                    fullWidth
                                    onChange={(e) => {
                                        this.setState({
                                            user_email: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="name"
                                    label="Enter ser Mobile"
                                    variant="standard"
                                    fullWidth
                                    onChange={(e) => {
                                        this.setState({
                                            user_mobile: e.target.value
                                        })
                                    }}
                                />
                            </Grid> */}
                                    <Button sx={{
                                        margin: "23px"
                                    }} style={{
                                        backgroundColor: "darkslategrey"
                                    }} variant="contained" color="primary" type="submit"
                                        onClick={(e) => {
                                            this.setState({ display: false, cat_name: "", cat_desc: "" })
                                            console.log("click")
                                            this.props.addUserFromExcel(this.props.login.org_id, this.state.mailData)
                                        }}
                                    >
                                        Submit
                                    </Button>
                                    <Button sx={{
                                        margin: "23px"

                                    }}
                                        onClick={() => {
                                            this.setState({
                                                display: false,

                                            })
                                        }}


                                        variant="text" color="primary" type="submit">
                                        Cancel
                                    </Button>
                                </Grid>
                            </TabPanel>

                        </Tabs>



                        {/* ==================================================================================== */}


                        {/* <Grid container style={{
                            padding: "1em",
                            backgroundColor: "aliceblue",
                            boxShadow: " inset 0px 0px 3px 0px",
                        }} >

                            <Grid item xs={12}>
                                <TextField
                                    id="name"
                                    label="Select Category"
                                    variant="standard"
                                    fullWidth
                                    select
                                    onChange={(e) => {
                                        this.setState({
                                            category: e.target.value
                                        })
                                    }}
                                >{this.props.mail.category.map((category, index) => (
                                    <MenuItem key={index} value={category._id}>
                                        {category.name}
                                    </MenuItem>
                                ))}</TextField>
                            </Grid>
                            <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
                                <TextField
                                    id="name"
                                    label="Enter User Name"
                                    variant="standard"
                                    fullWidth
                                    disabled={this.state.selectedFile !== ""}
                                    onChange={(e) => {
                                        this.setState({
                                            user_name: e.target.value
                                        })
                                    }}
                                />
                                <div>

                                    <input type="file" id="upload" hidden accept=".xls, .xlsx" onChange={this.handleFileUpload} />
                                    <label style={{
                                        display: "inline-block",
                                        backgroundColor: "darkslategrey",
                                        color: "white",
                                        padding: " 0.5rem",
                                        //  width: "59px",
                                        fontSize: "8px",
                                        fontFamily: "sans-serif",
                                        borderRadius: " 0.3rem",
                                        cursor: "pointer",
                                        //  marginTop: "1rem",
                                    }} for="upload"><CloudUploadIcon /></label>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="name"
                                    label="Enter Email"
                                    variant="standard"
                                    fullWidth
                                    onChange={(e) => {
                                        this.setState({
                                            user_email: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="name"
                                    label="Enter ser Mobile"
                                    variant="standard"
                                    fullWidth
                                    onChange={(e) => {
                                        this.setState({
                                            user_mobile: e.target.value
                                        })
                                    }}
                                />
                            </Grid>
                            <Button sx={{
                                margin: "23px"
                            }} style={{
                                backgroundColor: "darkslategrey"
                            }} variant="contained" color="primary" type="submit"
                                onClick={(e) => {
                                    this.setState({ display: false, cat_name: "", cat_desc: "" })
                                    this.props.addUser(this.props.login.org_id, this.state.user_name, this.state.user_email, this.state.user_mobile, this.state.category)
                                }}
                            >
                                Submit
                            </Button>
                            <Button sx={{
                                margin: "23px"

                            }}
                                onClick={() => {
                                    this.setState({
                                        display: false,

                                    })
                                }}


                                variant="text" color="primary" type="submit">
                                Cancel
                            </Button>
                        </Grid> */}

                    </Box>

                </Box>

            </Container>
        )
    }
}




