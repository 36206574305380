import { LOADER, PROGRESS } from './constant';
export function setLoader() {
  return {
    type: LOADER,
    payload: true,
  };
}
export function unsetLoader() {
  return {
    type: LOADER,
    payload: false,
  };
}
export function setProgress() {
  return {
    type: PROGRESS,
    payload: true,
  };
}
export function unsetProgress() {
  return {
    type: PROGRESS,
    payload: false,
  };
}
