import {
  ALL_CATEGORY,
  CATEGORY_LENGTH,
  SEARCH_CATEGORY
} from "./Constant";

// Utility function to handle GraphQL requests
import { handleGraphQLRequest } from '../../utils/utils';
import { getCategoryQuery, getAllCategoryQuery } from '../../graphql/query/categoryq';
import { setCategorymutation, delCategorymutation, resetCategorymutation, getDepartmentQuery } from '../../graphql/mutation/categorym';
import { set_snack_bar } from "../../common/snackbar/action";



// Action creators
export function setAllCategory(categoryData) {
  return {
    type: ALL_CATEGORY,
    payload: categoryData,
  };
}

export function setCategoryLength(categoryLength) {
  return {
    type: CATEGORY_LENGTH,
    payload: categoryLength,
  };
}

//---------------------------------------------------VIEWALL----------------------------------------------------------//
export function viewAllCategory(orgId, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = getCategoryQuery(orgId, page, rowPerPage);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Category Found', false);
    if (!response.errors && response.data) {
      const categoryData = response.data.categorysPagination.categorys;
      dispatch(setAllCategory(categoryData));
      const categoryLength = response.data.categorysPagination.totalCategories;
      dispatch(setCategoryLength(categoryLength));
    }
    else {
      const categoryData = [];
      dispatch(setAllCategory(categoryData));
      const categoryLength = 0;
      dispatch(setCategoryLength(categoryLength));
    }
  };
}
export function viewAllOrgCategory(orgId) {
  return async (dispatch) => {
    let requestBody = getAllCategoryQuery(orgId);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Category Found', false);
    if (!response.errors && response.data) {
      const categoryData = response.data.categorys;
      dispatch(setAllCategory(categoryData));
    }
    else {
      const categoryData = [];
      dispatch(setAllCategory(categoryData));
    }
  };
}


//---------------------------------------------------ADD----------------------------------------------------------//
export function createCategory(orgId, category_name, category_dept, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = setCategorymutation(orgId, category_name, category_dept);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Category created successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllCategory(orgId, page, rowPerPage));
    }
  };
}

//---------------------------------------------------EDIT----------------------------------------------------------//
export function editCategory(orgId, category_id, category_name, category_dept, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = resetCategorymutation(orgId, category_id, category_name, category_dept, page, rowPerPage);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Changes made successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllCategory(orgId, page, rowPerPage));
    }
  };
}

//---------------------------------------------------DELETE----------------------------------------------------------//
export function deleteCategory(orgId, categoryId, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = delCategorymutation(categoryId);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Category deleted successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllCategory(orgId, page, rowPerPage));
    }
  };
}

//---------------------------------------------------SEARCH----------------------------------------------------------//
export function searchCategory(allCategory, e) {
  return (dispatch) => {
    if (e === "") {
      dispatch({ type: SEARCH_CATEGORY, payload: allCategory });
    }
    else {
      const newArray = allCategory.filter((el) => {
        return (el.name.toLowerCase().includes(e.toLowerCase()));
      })
      dispatch({ type: SEARCH_CATEGORY, payload: newArray })
    }
  };
}
