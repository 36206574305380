import {
  ALL_TEMPLATE,
  TEMPLATE_LENGTH,
  SEARCH_TEMPLATE
} from "./Constant";

// Utility function to handle GraphQL requests
import { handleGraphQLRequest } from '../../utils/utils';
import {
  getTemplateQuery,
  getAllTemplateQuery,
  getAllCatTemplateQuery
} from '../../graphql/query/addEmailTemplateq';
import {
  setTemplatemutation,
  setExcelTemplatemutation,
  delTemplatemutation,
  resetTemplatemutation
} from '../../graphql/mutation/addEmailTemplatem';
import { set_snack_bar } from "../../common/snackbar/action";



// Action creators
export function setAllTemplate(templateData) {
  return {
    type: ALL_TEMPLATE,
    payload: templateData,
  };
}

export function setTemplateLength(templateLength) {
  return {
    type: TEMPLATE_LENGTH,
    payload: templateLength,
  };
}

//---------------------------------------------------VIEWALL----------------------------------------------------------//
export function viewAllTemplate(orgId, page, rowPerPage, search, type) {
  return async (dispatch) => {
    let requestBody = getTemplateQuery(orgId, page, rowPerPage, search, type);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Template Found', false); 
    if (!response.errors && response.data) {
      const templateData = response.data.templatesPagination.templates;
      dispatch(setAllTemplate(templateData));
      const templateLength = response.data?.templatesPagination?.totalTemplates;
      dispatch(setTemplateLength(templateLength));
    }
    else {
      const templateData = []
      dispatch(setAllTemplate(templateData));
      const templateLength = 0
      dispatch(setTemplateLength(templateLength));
    }
  };
}
export function viewAllOrgTemplate(orgId) {
  return async (dispatch) => {
    let requestBody = getAllTemplateQuery(orgId);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Template Found', false);
    if (!response.errors && response.data) {
      const templateData = response.data.templates;
      dispatch(setAllTemplate(templateData));
    }
    else {
      const templateData = []
      dispatch(setAllTemplate(templateData));
    }

  };
}
export function viewAllCateTemplate(category_id, type) {
  return async (dispatch) => {
    let requestBody = getAllCatTemplateQuery(category_id, type);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Template Found', false);
    if (!response.errors && response.data) {
      const templateData = response.data.templates;
    dispatch(setAllTemplate(templateData));
    }
    else {
      const templateData = []
      dispatch(setAllTemplate(templateData));
    }
  };
}


//---------------------------------------------------ADD----------------------------------------------------------//
export function createTemplate(orgId, name, email, phone, category, type) {
  return async (dispatch) => {
    let requestBody = setTemplatemutation(orgId, name, email, phone, category, type);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Template created successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllTemplate(orgId, 0, 10, "", type));
    }
  };
}

export function createExcelTemplate(orgId, category, data) {
  return async (dispatch) => {
    let requestBody = setExcelTemplatemutation(orgId, category, data);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Template created successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllTemplate(orgId, 0, 10, ""));
    }
  };
}

//---------------------------------------------------EDIT----------------------------------------------------------//
export function editTemplate(orgId, templateId, name, email, phone, category, page, rowPerPage, type) {
  return async (dispatch) => {
    let requestBody = resetTemplatemutation(templateId, name, email, phone, category, type);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Changes made successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllTemplate(orgId, page, rowPerPage, "", type));
    }
  };
}

//---------------------------------------------------DELETE----------------------------------------------------------//
export function deleteTemplate(orgId, templateId, page, rowPerPage, type) {
  return async (dispatch) => {
    let requestBody = delTemplatemutation(templateId);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Template deleted successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllTemplate(orgId, page, rowPerPage, "", type));
    }
  };
}

//---------------------------------------------------SEARCH----------------------------------------------------------//
export function searchTemplate(allTemplate, e) {
  return (dispatch) => {
    if (e === "") {
      dispatch({ type: SEARCH_TEMPLATE, payload: allTemplate });
    }
    else {
      const newArray = allTemplate.filter((el) => {
        return (el.name.toLowerCase().includes(e.toLowerCase()));
      })
      dispatch({ type: SEARCH_TEMPLATE, payload: newArray })
    }
  };
}
