// Define your GraphQL queries here
export const getSendEmailQuery = (orgId, page, rowPerPage, search) => {
  return {
    query: `
      query {
        campaignsPagination(org_id: "${orgId}", page: ${parseInt(page)}, rowPerPage: ${parseInt(rowPerPage)}, search: "${search}") {
          totalCampaign
          campaign {
            _id
            name
            subject
            email_type
            reciver_id
            sending_account_id
            template
            createdAt
            attachmentType
            attachment
            status  
            mail_status{
              email
              status
              messageId
            }
          }
        }
      }
    `,
    variables: {
      org_id: orgId,
      page: parseInt(page),
      rowPerPage: parseInt(rowPerPage),
      search: search
    },
  };
};
export const getAllSendEmailQuery = (orgId) => {
  return {
    query: `
      query {
        campaigns(org_id: "${orgId}") {
          _id
          name
        }
      }
    `,
    variables: {
      org_id: orgId,
    },
  };
};
export const getAllCatSendEmailQuery = (categoryId) => {
  return {
    query: `
      query {
        sendEmailByCategoryId(categoryId: "${categoryId}") {
          _id
          temp_name
          temp_body
        }
      }
    `,
    variables: {
      categoryId: categoryId,
    },
  };
};
