import {
  ALL_DEPARTMENT,
  DEPARTMENT_LENGTH,
  SEARCH_DEPARTMENT,
  DEPARTMENT
} from "./Constant";

// Utility function to handle GraphQL requests
import { handleGraphQLRequest } from '../../utils/utils';
import { getDepartmentQuery, departmentQuery } from '../../graphql/query/departmentq';
import { setDepartmentmutation, delDepartmentmutation, resetDepartmentmutation } from '../../graphql/mutation/departmentm';
import { set_snack_bar } from "../../common/snackbar/action";



// Action creators
export function setAllDepartment(departmentData) {
  return {
    type: ALL_DEPARTMENT,
    payload: departmentData,
  };
}

export function setDepartmentLength(departmentLength) {
  return {
    type: DEPARTMENT_LENGTH,
    payload: departmentLength,
  };
}

export function setDepartment(department) {
  return {
    type: DEPARTMENT,
    payload: department,
  };
}

//---------------------------------------------------VIEWALL----------------------------------------------------------//
export function viewAllDepartment(orgId, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = getDepartmentQuery(orgId, page, rowPerPage);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Department Found', false);
    if (!response.errors && response.data) {
    const departmentData = response.data.departmentsPagination.departments;
    dispatch(setAllDepartment(departmentData));
    const departmentLength = response.data?.departmentsPagination.totalDepartments;
    dispatch(setDepartmentLength(departmentLength));
    }
    else{
      const departmentData = [];
    dispatch(setAllDepartment(departmentData));
    const departmentLength = 0;
    dispatch(setDepartmentLength(departmentLength));
    }
  };
}


//---------------------------------------------------ADD----------------------------------------------------------//
export function createDepartment(orgId, department_name, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = setDepartmentmutation(orgId, department_name);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Department created successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllDepartment(orgId, page, rowPerPage));
    }
  };
}

//---------------------------------------------------EDIT----------------------------------------------------------//
export function editDepartment(orgId, department_id, department_name, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = resetDepartmentmutation(orgId, department_id, department_name, page, rowPerPage);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Changes made successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllDepartment(orgId, page, rowPerPage));
    }
  };
}

//---------------------------------------------------DELETE----------------------------------------------------------//
export function deleteDepartment(orgId, departmentId, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = delDepartmentmutation(departmentId);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Department deleted successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllDepartment(orgId, page, rowPerPage));
    }
  };
}

//---------------------------------------------------SEARCH----------------------------------------------------------//
export function searchDepartment(alldepartment, e) {
  return (dispatch) => {
    if (e === "") {
      dispatch({ type: SEARCH_DEPARTMENT, payload: alldepartment });
    }
    else {
      const newArray = alldepartment.filter((el) => {
        return (el.name.toLowerCase().includes(e.toLowerCase()));
      })
      dispatch({ type: SEARCH_DEPARTMENT, payload: newArray })
    }
  };
}

//---------------------------------------------------Department----------------------------------------------------------//
export function viewDepartment(orgId) {
  return async (dispatch) => {
    let requestBody = departmentQuery(orgId);
    const response = await handleGraphQLRequest(dispatch, requestBody, '', false);
    if (!response.errors && response.data) {
    const departments = response.data.departments;
    dispatch(setDepartment(departments));
    }
    else{
      const departments = [];
      dispatch(setDepartment(departments));
    }
  };
}