export const setDepartmentmutation = (orgId, department_name) => {
    return {
        query: `
            mutation CreateDepartment($departmentInput: DepartmentInput!) {
                createDepartment(departmentInput: $departmentInput) {
                    _id
                    name
                }
            }
        `,
        variables: {
            departmentInput: {
                org_id: orgId,
                name: department_name,
            },
        },
    };
};

export const resetDepartmentmutation = (orgId, department_id, department_name) => {
    return {
        query: `
        mutation updateDepartment {
            updateDepartment(department: {
             departmentId:"${department_id}",
              updatedDepartment:{
                name:"${department_name}"
              }
            }) {
              _id
              name
            }
          }
        `,
        variables: {
            departmentInput: {
                org_id: orgId,
                _id: department_id,
                name: department_name,
            },
        },
    };
};

export const delDepartmentmutation = (departmentId) => {
    return {
        query: `
        mutation {
            deleteDepartment(departmentId: "${departmentId}") {
              _id
              name
              deleted
            }
          }
        `,
        variables: {
            _id: departmentId,
        },
    };
};

