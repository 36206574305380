import React, { Component } from "react";
import { connect } from "react-redux";
import Dashboard from "../Components/Dashboard";
import { close_snack_bar } from "../../../common/snackbar/action";
import {
    ViewDashboardData
} from "../Action";
import {
    viewProductUser
} from "../.././Users/Action";
// import { viewDepartment } from '../../Department/Action'
export class CategoryContainer extends Component {
    render() {
        return (
            <Dashboard {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        dashboard: store.dashboard,
        user: store.user,

    };
};
export const mapDispatchToProps = dispatch => {
    return {

        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        ViewDashboardData: (org_id) => {
            dispatch(ViewDashboardData(org_id))
        },
        viewProductUser: (org_id, product) => {
            dispatch(viewProductUser(org_id, product));
        },




    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer);