/* eslint-disable no-useless-concat */
import {
  ADMIN_USER

} from "./Constant";
import UNIVERSAL from "../../config/config";
import { setLoader, unsetLoader, setProgress, unsetProgress } from "../../common/loader/action";
import { set_snack_bar } from "../../common/snackbar/action";

//--------------------------------------------------ADD--------------------------------------------------------------//
export function addProductUser(token, user_id, product, org_id, name, email, phone, access, type) {
  return (dispatch) => {
    dispatch(setProgress());
    return fetch(UNIVERSAL.SSO_URL + "api/v1/auth/create_user_for_newsletter", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token
      },
      body: JSON.stringify({
        added_by: user_id,
        org_id: org_id,
        name: name,
        email_id: email,
        mobile: phone,
        accesstype: access,
        product: product,
        type: type,
        accessToken: token
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
          dispatch(viewProductUser(org_id, user_id, token, product, type));
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(unsetProgress());
        } else {
          dispatch(viewProductUser(org_id, user_id, token, product, type));
          dispatch(unsetProgress());
          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unsetProgress());
        dispatch(set_snack_bar(true, responseJson.message));
      })
      .catch((error) => {
        dispatch(unsetProgress());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

//--------------------------------------------------VIEWALL--------------------------------------------------------------//
export function viewProductUser(org_id, user_id, token, product, type) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.SSO_URL + "api/v1/auth/view_all_newsletteruser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token
      },
      body: JSON.stringify({
        org_id: org_id,
        product: product,
        user_id: user_id,
        type
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson, "dddddddddd");
        if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
          dispatch({ type: ADMIN_USER, payload: responseJson.data.user_details });
          dispatch(unsetLoader());
        } else {
          dispatch({ type: ADMIN_USER, payload: [] })
          dispatch(unsetLoader());
        }
        dispatch(unsetLoader());
      })
      .catch((error) => {
        dispatch({ type: ADMIN_USER, payload: [] })
        console.error(error);
      });
  };
}

//--------------------------------------------------DELETE--------------------------------------------------------------//
export function deleteProductUser(org_id, user_id, token, product, type) {
  return (dispatch) => {
    dispatch(setLoader());
    dispatch(setProgress());
    return fetch(UNIVERSAL.SSO_URL + "api/v1/auth/delete_user", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token
      },
      body: JSON.stringify({
        user_id: user_id,
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.statusCode === 200 || responseJson.statusCode === 201) {
          dispatch(viewProductUser(org_id, user_id, token, product, type));
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(unsetLoader());
          dispatch(unsetProgress());

        } else {
          dispatch(viewProductUser(org_id, user_id, token, product, type));
          dispatch(unsetLoader());
          dispatch(unsetProgress());

          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unsetLoader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}
export function updateProductUser(user_id, name, email_id, mobile, access, org_id, product) {
  return (dispatch) => {
    dispatch(setLoader());
    dispatch(setProgress());
    return fetch(UNIVERSAL.SSO_URL + "v1/update_user", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        name: name,
        email_id: email_id,
        mobile: mobile,
        access: access,
        org_id: org_id,
        product: product,
      })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewProductUser(org_id, product));
          dispatch(set_snack_bar(true, responseJson.message));
          dispatch(unsetLoader());
          dispatch(unsetProgress());

        } else {
          dispatch(viewProductUser(org_id, product));
          dispatch(unsetLoader());
          dispatch(unsetProgress());

          dispatch(set_snack_bar(true, responseJson.message));
        }
        dispatch(unsetLoader());
      })
      .catch((error) => {
        console.error(error);
      });
  };
}