import React, { Component } from "react";
import { connect } from "react-redux";
import Category from "../Components/Subscriber";
import { close_snack_bar } from "../../../common/snackbar/action";
import {
    viewAllOrgCategory,
} from "../../Category/Action";
import {
    createSubscriber,
    editSubscriber,
    deleteSubscriber,
    searchSubscriber,
    viewAllSubscriber,
    createExcelSubscriber
} from "./../Action";
export class CategoryContainer extends Component {
    render() {
        return (
            <Category {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        subscriber:store.subscriber,
        snackbar: store.snackbar,
        category:store.category,
        department:store.department,
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        viewAllOrgCategory: (orgId) => {
            dispatch(viewAllOrgCategory(orgId));
        },
        viewAllSubscriber : (orgId, page, rowPerPage, search) => {
            dispatch(viewAllSubscriber (orgId, page, rowPerPage, search));
        },
        createSubscriber: (orgId, name, email, phone, category,) => {
            dispatch(createSubscriber(orgId, name, email, phone, category,));
        },
        createExcelSubscriber: (orgId, category, data) => {
            dispatch(createExcelSubscriber(orgId, category, data));
        },
        editSubscriber: (orgId, subscriberId, name, email, phone, category, page, rowPerPage) => {
            dispatch(editSubscriber(orgId, subscriberId, name, email, phone, category, page, rowPerPage));
        },
        deleteSubscriber: (orgId, categoryId, page, rowPerPage) => {
            dispatch(deleteSubscriber(orgId, categoryId, page, rowPerPage));
        },
        searchSubscriber: (allCategory, e) => {
            dispatch(searchSubscriber(allCategory, e));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer);