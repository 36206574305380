import React, { Component } from "react";
// import firebase from "firebase";
// import { firebase_config } from "../../constants/ActionTypes";

export default class LoginController extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      page: ""
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token") !== null) {
      this.props.fetchToken(localStorage.getItem("token"),this.props.login.product )

    }
  }

  render() {
    return (
      <div />
    );
  }
}
