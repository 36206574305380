import React, { Component } from 'react'
import {
  Grid,
  Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  Button,
  CircularProgress
} from "@mui/material";
import SearchIcon from '../../image/search.png'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import Icon from "@mui/material/Icon";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import '../../../common/styles.scss';
import Snackbar from "../../../common/snackbar/components/snackbar"
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import '../../../common/introCss.scss'

export default class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      searching: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
      category_id: '',
      category_name: '',
      category_dept: 'N',
      category_desc: '',
      category_dept_name: '',
      page: 0,
      rowsPerPage: 10,
    };
  }

  startIntro = () => {

    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          intro: 'Welcome to Category!',
        },
        {
          element: '#tableCat', // Replace with the actual element ID or class
          intro: 'this is a list of Categories',
        },
        {
          element: '#addCat',
          intro: 'You can add your own category',
        },
        {
          element: '#editCat',
          intro: 'You can also make changes to particular category according to your needs',
        },
        {
          element: '#deleteCat',
          intro: 'You can delete unnecessary category',
        },
        {
          element: '#searchCat',
          intro: 'Search your category by name',
        },
      ],
    });
    intro.start();
    localStorage.setItem("cat_intro", true)
  };

  componentDidMount() {
    this.props.viewAllCategory(this.props.login.org_id, this.state.page, this.state.rowsPerPage);
    this.props.viewDepartment(this.props.login.org_id);
    if (localStorage.getItem("cat_intro")!=="true") {
      this.startIntro();
    }
  }

  handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: newRowsPerPage, page: 0 });
    this.props.viewAllCategory(this.props.login.org_id, 0, newRowsPerPage);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    // Fetch data with the new page value
    this.props.viewAllCategory(this.props.login.org_id, newPage, this.state.rowsPerPage);
  };



  render() {
    const { snackbar, close_snack_bar } = this.props
    return (
      <Grid>
        <Grid container justifyContent={'left'}>
          <Typography className='topic'>Category</Typography>
        </Grid>
        <LoaderCon />
        <Grid container className='page'>
          <Grid item xs={12} className='box'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <FormControl fullWidth variant="standard" id='searchCat'>
                  <OutlinedInput
                    type={'text'}
                    className='textfield'
                    sx={{
                      '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                        boxShadow: '0px 2px 0px lightgray',
                      },
                      '.MuiOutlinedInput-notchedOutline': { border: '1px solid #E2E0DD' },
                      height: '48px',
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <LoadingButton
                            loading={this.state.searching}
                            loadingPosition="center"
                            variant="text"
                            onClick={() => {
                              this.setState({
                                add: true,
                              });
                            }}
                          >
                            {!this.state.searching && <img src={SearchIcon} alt="Search Category" style={{ font: '24px' }} />}
                          </LoadingButton>
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Search"
                    onChange={(event) => {
                      this.setState({ searching: event.target.value !== '' ? true : false });
                      this.props.searchCategory(this.props.category.allCategory, event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={2} >
                {/* Adjust the xs value according to your layout */}
                <LoadingButton
                  id="addCat"
                  fullWidth
                  style={{ height: '48px' }}
                  className='add'
                  startIcon={this.props.loader.progress ? '' : <AddRoundedIcon />}
                  loading={this.props.loader.progress}
                  loadingPosition='center'
                  variant='contained'
                  onClick={() => {
                    this.setState({
                      add: true,
                      category_name: '',
                      category_dept: '',
                    });
                  }}
                >
                  {this.props.loader.progress ? (
                    <CircularProgress
                      size={24} // Adjust the size of the circular loader as needed
                      style={{ color: 'white' }}
                    />
                  ) :
                    <Typography>Category</Typography>
                  }
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid>
              <TableContainer id="tableCat" >
                <Table stickyHeader aria-label="sticky table" style={{ marginTop: '20px' }}>
                  <TableHead className="customTableHead">
                    <TableRow >
                      <TableCell justifyContent={'center'}>
                        <Typography className='headtext'>S No</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext'>Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext' >Department</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext' textAlign={'center'}>Action</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(this.props.category.searchCat) && this.props.category.searchCat.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1}>
                          <TableCell justifyContent={'center'}>
                            <Typography className='celltext'>{index + 1}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className='celltext'>{row.name}</Typography>
                          </TableCell>
                          <TableCell >
                            <Typography className='celltext'>{row.department?.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                              <Icon
                                id='editCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    edit: true,
                                    category_id: row._id,
                                    category_name: row.name,
                                    category_dept: row.department?._id,
                                    category_dept_name: row.department?.name,
                                  });
                                }}
                                style={{
                                  color: "#FFA741",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 231, 203, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "10%",
                                }}
                              >
                                edit_square
                              </Icon>
                              <Icon
                                id='deleteCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    delete: true,
                                    category_id: row._id,
                                  });
                                }}
                                style={{
                                  color: "#C43232",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 234, 227, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "10%",
                                }}
                              >
                                delete
                              </Icon>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={this.props.category.catlength}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
        {/*--------------------Add-------------------------*/}
        <Dialog
          open={this.state.add}
          maxWidth="xs"
          fullWidth
          sx={{
            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle className='titlespace'>
            <Grid container marginTop={1}>
              <Grid item xs={.5} />
              <Grid item>
                <Typography className='title'>Add Category</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={.5} />
              <Grid item xs={11} direction='column'>
                <Typography style={{ fontWeight: 'bold' }} >
                  Name
                </Typography>
                <TextField
                  variant='outlined'
                  type="text"
                  fullWidth
                  placeholder='Enter your name'
                  className='textfield'
                  sx={{
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                      boxShadow: '0px 2px 0px lightgray',
                    },
                  }}
                  onChange={(event) => {
                    this.setState({ category_name: event.target.value });

                  }}
                />

                <Typography style={{ marginTop: 15, fontWeight: 'bold' }}>
                  Department Name
                </Typography>

                <Select
                  fullWidth
                  value={this.state.category_dept}  // Changed from this.state.dept to this.state.category_dept
                  onChange={(e) => {
                    this.setState({ category_dept: e.target.value });
                  }}
                  className='select'
                  sx={{
                    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                      boxShadow: '0px 2px 0px lightgray',
                    },
                  }}
                >
                  <MenuItem disabled value="">
                    {'Select Department'}
                  </MenuItem>
                  {Array.isArray(this.props.department.department) && this.props.department.department.map((row) => (
                    <MenuItem key={row._id} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>

              </Grid>
              <Grid item xs={.5} />
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%', marginTop: '3%' }} >
            <Button
              variant="outlined"
              className='cancel'
              onClick={() => {
                this.setState({ add: false });
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.category_name === '' && this.statecategory_dept === ''}
              variant="contained"
              className={(this.state.category_name === '' && this.state.category_dept === '') ? 'disabled' : 'submit'}
              onClick={() => {
                this.setState({
                  add: false,
                  category_name: '',
                  category_dept: '',
                });
                this.props.createCategory(this.props.login.org_id, this.state.category_name, this.state.category_dept, this.state.page, this.state.rowsPerPage);
              }}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog >
        {/*--------------------Edit-------------------------*/}
        <Dialog
          open={this.state.edit}
          maxWidth="xs"
          fullWidth
          sx={{
            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle className='titlespace'>
            <Grid container marginTop={1}>
              <Grid item xs={.5} />
              <Grid item>
                <Typography className='title'>Add Category</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={.5} />
              <Grid item xs={11} direction='column'>
                <Typography style={{ fontWeight: 'bold' }} >
                  Name
                </Typography>
                <TextField
                  variant='outlined'
                  type="text"
                  fullWidth
                  placeholder='Enter your name'
                  className='textfield'
                  sx={{
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                      boxShadow: '0px 2px 0px lightgray',
                    },
                  }}
                  value={this.state.category_name}
                  onChange={(event) => {
                    this.setState({ category_name: event.target.value });

                  }}
                />

                <Typography style={{ marginTop: 15, fontWeight: 'bold' }}>
                  Department Name
                </Typography>

                <Select
                  fullWidth
                  value={this.state.category_dept}
                  onChange={(e) => {
                    this.setState({ category_dept: e.target.value });
                  }}
                  className='select'
                  sx={{
                    '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                      boxShadow: '0px 2px 0px lightgray',
                    },
                  }}
                >
                  <MenuItem disabled value="N">
                    {'Select Department'}
                  </MenuItem>
                  {Array.isArray(this.props.department.department) && this.props.department.department.map((row) => (
                    <MenuItem key={row._id} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={.5} />
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%', marginTop: '3%' }} >
            <Button
              variant="outlined"
              className='cancel'
              onClick={() => {
                this.setState({ edit: false });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className='submit'
              onClick={() => {
                this.setState({
                  edit: false,
                  category_name: '',
                  category_dept: '',
                });
                this.props.editCategory(this.props.login.org_id, this.state.category_id, this.state.category_name, this.state.category_dept, this.state.page, this.state.rowsPerPage);
              }}
            >
              Save Changes
            </Button>
          </DialogActions>
        </Dialog >
        {/*--------------------Delete-------------------------*/}
        <Dialog
          open={this.state.delete}
          maxWidth="xs"
          fullWidth
          sx={{
            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle className='titlespace'>
            <Grid container marginTop={1}>
              <Grid item xs={.5} />
              <Grid item>
                <Typography className='title'>Delete Category</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={.5} />
              <Grid item xs={11} direction='column'>
                <Typography style={{ fontWeight: 'bold' }} >
                  Are you sure do you want to delete this category?
                </Typography>
              </Grid>
              <Grid item xs={.5} />
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%', }} >
            <Button
              variant="outlined"
              className='cancel'
              onClick={() => {
                this.setState({ delete: false });
              }}
            >
              No, Cancel it.
            </Button>
            <Button
              variant="contained"
              className='submit'
              onClick={() => {
                this.setState({
                  delete: false
                });
                this.props.deleteCategory(this.props.login.org_id, this.state.category_id, this.state.page, this.state.rowsPerPage);
              }}
            >
              Yes, Delete it.
            </Button>
          </DialogActions>
        </Dialog >
      </Grid>
    )
  }
}
