export const setTemplatemutation = (orgId, temp_name, temp_body, temp_type, category, type) => {
    return {
        query: `
            mutation CreateTemplate($templateInput: TemplateInput!) {
                createTemplate(templateInput: $templateInput) {
                    _id
                    temp_body
                    temp_name
                    temp_type
                    type
                }
            }
        `,
        variables: {
            templateInput: {
                org_id: orgId,
                temp_name: temp_name,
                temp_body: temp_body,
                temp_type: temp_type,
                category: category,
                type: type,
            },
        },
    };
};
export const setExcelTemplatemutation = (orgId, category, data) => {
    return {
        query: `
        mutation CreateTemplate($orgId: ID!, $category: ID!, $templateInput: [TemplateExcelInput!]!) {
            createTemplateExcel(org_id: $orgId, category: $category, templateInput: $templateInput) {
              _id
              name
            }
          }
        `,
        variables: {
            orgId,
            category,
            templateInput: data,
        },
    };
};

export const resetTemplatemutation = (template_id, name, email, phone, category) => {
    var templateId = template_id
    var updateTemplateInput = {
        name:name,
        email: email,
        phone: phone,
        category: category
    }
    return {
        query: `
        mutation UpdateTemplate($templateId: ID!, $updateTemplate: TemplateInput2!) {
            updateTemplate(template: { templateId: $templateId, updateTemplate: $updateTemplate }) {
              _id
              name
              email
              phone
              category
            }
          }
        `,
        variables: {
            templateId,
            updateTemplate: updateTemplateInput,
        },
    };
};

export const delTemplatemutation = (templateId) => {
    return {
        query: `
        mutation {
            deleteTemplate(templateId: "${templateId}") {
              _id
              temp_name
              deleted
            }
          }
        `,
        variables: {
            templateId: templateId,
        },
    };
};

