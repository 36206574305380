import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "../styles/style.scss";
import LoaderCon from "../../common/loader/containers/loader_cont";
import { TextField, Box, CardContent, Avatar } from "@mui/material";
// import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
// import logo from "../../pages/Images/logo.png";
import LMS1 from "../../pages/image/EmailKaroLoginOnbaordingScreen.png";
// import google from "../../pages/Images/google.png";
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import Loader from '../../../common/loader/components/loader';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import PhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import TextField from '@mui/material/TextField';
import 'react-phone-input-2/lib/style.css';
import Dialog from "@mui/material/Dialog";
// import Avatar from "../../pages/Images/Avatar.png";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import { Pressable } from "react-native";




const defaultTheme = createTheme();


class Vieworg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            otp: "",
            change_num: false,
            enter_num: false,
            bt_type: true,
            time: {},
            seconds: 30,
            org: '',
            createorg: false,
            logo: "",
        };
    }
    componentDidMount() {
        console.log(this.props.login.user_id)
        this.props.viewAccessByUser(this.props.login.user_id)
        console.log(this.props.login.org_user)
    }
    render() {
        const { org } = this.state;
        return (
            <div>
                <ThemeProvider theme={defaultTheme}>
                    <Grid container component="main" sx={{ height: '90vh' }}>
                        <Grid
                            item
                            xs={false}
                            sm={4}
                            md={7}
                            sx={{
                                backgroundImage: `url(${LMS1})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundColor: (t) =>
                                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                // height: '100%',
                                width: '100%',
                                borderRadius: '10px',
                            }}
                        />
                        <CssBaseline />

                        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                            {/* <img src={logo} style={{ marginTop: "3%", marginLeft: "8%" }} alt="Logo" /> */}
                            <Box
                                sx={{
                                    my: 8,
                                    mx: 4,
                                    p: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: "30px",
                                }}
                            >
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <center>
                                        <Typography style={{ fontSize: "25px", fontWeight: 500 }}>
                                            Organizations
                                        </Typography>
                                    </center>
                                    <Card style={{ width: "400px", height: "400px", background: "white", boxShadow: "2px 2px 15px rgba(0, 0, 0, 0.2)", marginTop: "20px" }}>
                                        <CardContent>

                                            {Array.isArray(this.props.login.org_user) && this.props.login.org_user.length === 0 ? <center style={{ fontSize: "18px", fontWeight: 800 }}>"No Organization" </center> :
                                                <Grid className="companylist" style={{ height: "300px", overflowY: "scroll", marginTop: "2%" }}>

                                                    {Array.isArray(this.props.login.org_user) && this.props.login.org_user.map((e) => {
                                                        return (

                                                            <Grid style={{ display: "flex", marginLeft: "5%", marginRight: "3%" }}>
                                                                <Button
                                                                    onClick={() => {
                                                                        this.props.viewUserOrgDetails(this.props.login.user_id, e._id)
                                                                    }}
                                                                >
                                                                    <Avatar src={e.org_logo} style={{ marginRight: "18px" }} />
                                                                    {e.org_name}
                                                                </Button>
                                                            </Grid>
                                                        )
                                                    })
                                                    }
                                                </Grid>}
                                        </CardContent>
                                        <center>

                                            <Button
                                                onClick={() => {
                                                    this.setState({ createorg: true });
                                                }}
                                                variant="contained" style={{ backgroundColor: "000066" }}>Create Organizations</Button>
                                        </center>

                                    </Card>
                                </Box>
                            </Box>
                        </Grid>


                    </Grid>
                </ThemeProvider>


                <Dialog
                    onClose={() => this.setState({ createorg: false })}
                    open={this.state.createorg} style={{}}>
                    <Grid style={{ width: "350px", marginBottom: "2%", height:"auto",marginBottom:"20px"}}>

                        {/* <Box style={{width:"100%",marginLeft:"100%"}} onClick={()=>this.setState({ createorg: false })}> */}
                        <CloseIcon 
                         onClick={() => {
                            this.setState({ createorg: false });
                        }}
                        
                        
                        style={{ alignSelf: "flex-end",cursor:"pointer",marginLeft:"88%",marginTop:"15px" }} />
                        {/* </Box> */}
                        <Grid style={{ marginLeft: "3%", marginRight: "3%",  }}>

                            {/* <center><img src={Avatar} style={{ marginLeft: "5%" }} /></center> */}
                            <Avatar src={this.state.logo !== "" ? URL.createObjectURL(this.state.logo) : this.state.logo} style={{ marginLeft: "auto", marginRight: "auto" ,height:"60px",width:"60px"}}>
                                <input style={{ display: "none" }} type="file" id="file" onChange={(e) => { this.setState({ logo: e.target.files[0] }) }} />
                                <label htmlFor="file"><CameraAltIcon style={{ marginTop: "25%" }} /></label>
                            </Avatar>




                            <Grid style={{ marginTop: "7%",marginLeft:"20px" }}>
                                <Typography style={{fontWeight:500}}>Name:</Typography>
                                <TextField id="standard-basic" variant="standard" style={{ marginLeft: "5px",width:"280px"}}
                                    onChange={(e) => {
                                        this.setState({ org_name: e.target.value })
                                    }} />
                            </Grid>

                            <Grid style={{ marginLeft:"20px", marginTop: "7%" }}>
                                <Typography  style={{fontWeight:500}}>Email:</Typography>
                                <TextField id="standard-basic" variant="standard" style={{ marginLeft: "5px",width:"280px"}}
                                    onChange={(e) => {
                                        this.setState({ org_email: e.target.value })
                                    }}
                                />
                            </Grid>





                            <Grid style={{marginLeft:"20px", marginTop: "7%"}}>
                                <Typography  style={{fontWeight:500}}>Phone No:</Typography>
                                <TextField id="standard-basic" type="number" variant="standard" style={{ marginLeft: "5px",width:"280px"}}
                                    onChange={(e) => {
                                        this.setState({ org_num: e.target.value })
                                    }}
                                />
                            </Grid>

                            <Grid style={{ marginLeft:"20px", marginTop: "7%"}}>
                                <Typography  style={{fontWeight:500}}>GSTIN:</Typography>
                                <TextField id="standard-basic" variant="standard" style={{  marginLeft: "5px",width:"280px"}}
                                    onChange={(e) => {
                                        this.setState({ org_gstno: e.target.value })
                                    }}
                                />
                            </Grid>

                            <center><Button
                                onClick={() => {
                                    this.props.uploadOrgLogo(this.state.logo, this.state.org_name, this.state.org_email, this.state.org_num, this.state.org_gstno, this.props.login.user_id)
                                    this.setState({ createorg: false, });
                                }}

                                style={{ marginTop: "20px" }} variant="contained">Create</Button></center>




                        </Grid>

                    </Grid>
                </Dialog>
                <LoaderCon />

            </div>
        );
    }
}




export default Vieworg;




