import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../components/Admin";

import {
  // addProductUser,
  // viewProductUser,
  // deleteProductUser,
} from "../actions";
export class Controller extends Component {
  render() {
    return (
      <Login {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    mail: store.mail,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    // viewProductUser: (org_id,product) => {
    //   dispatch(viewProductUser(org_id, product));
    // },
    // addProductUser: (org_id, name, email, phone) => {
    //   dispatch(addProductUser(org_id, name, email, phone));
    // },
    // deleteProductUser: (user_id, org_id, product) => {
    //   dispatch(deleteProductUser(user_id, org_id, product));
    // },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);