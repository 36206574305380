import React, { Component } from "react";
import { connect } from "react-redux";
import Department from "../Components/Users";
import { close_snack_bar } from "../../../common/snackbar/action";
import {
    viewProductUser,
    addProductUser,
    updateProductUser,
    deleteProductUser
} from "../Action";
export class DepartmentContainer extends Component {
    render() {
        return (
            <Department {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        department: store.department,
        user: store.user,
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        viewProductUser: (org_id, user_id, token, product, type) => {
            dispatch(viewProductUser(org_id, user_id, token, product, type));
        },
        addProductUser: (token, user_id, product, org_id, name, email, phone, access, type) => {
            dispatch(addProductUser(token, user_id, product, org_id, name, email, phone, access, type));
        },
        updateProductUser: (user_id, name, email_id, mobile, access, org_id, product) => {
            dispatch(updateProductUser(user_id, name, email_id, mobile, access, org_id, product));
        },
        deleteProductUser: (org_id, user_id, token, product, type) => {
            dispatch(deleteProductUser(org_id, user_id, token, product, type));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentContainer);