import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../components/User";

import {
  addUser,
  viewUser,
  deleteUser,
  viewAllCategory,
  addUserFromExcel
} from "../actions";
export class Controller extends Component {
  render() {
    return (
      <Login {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    mail: store.mail,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
    viewAllCategory: (org_id) => {
      dispatch( viewAllCategory(org_id));
    },
    viewUser: (org_id) => {
      dispatch( viewUser(org_id));
    },
    addUser: (org_id, name, desc, mobile, category) => {
      dispatch(addUser(org_id, name, desc, mobile, category));
    },
    addUserFromExcel: (org_id, data) => {
      dispatch(addUserFromExcel(org_id, data));
    },
    deleteUser: (org_id, category_id) => {
      dispatch(deleteUser(org_id, category_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);