import {
  LOGIN,
  LOGOUT,
  SET_ALL_ORG,
  SETMOBILE,
  SET_USER_DETAILS,
  SET_ORG_USER_DEATILS,
  SET_ORG_DETAILS,
  SET_ORG_ID,
  SET_USER,
  SETLOGINORG,
  SET_ALL_DATA,
  SET_ORG,
  SET_ORG_USER_DETAILS,
  SET_ORG_DETAILS2,
  VIEW_ALL_DEPARTMENT,
  VIEW_ALL_PRIORITY,
  VIEW_ALL_STATUS,
  SET_DOC,
  SET_WALET_BY_ID,
  SWITCH
} from "./constant";
const initial_state = {
  user_id: "",
  name: "",
  type: "A",
  email: "",
  mobile: "",
  otpmobile: "",
  //  type: "T",
  type: "",
  email: "",
  mobile: "",
  otpmobile: "",
  isHome: true,
  orgHome: false,
  mobileOpen: false,
  loginModal: false,
  successfull: false,
  otpmodal: false,
  active: false,
  organizations: [],
  org_user_details: [],
  user_details: {},
  org_details: {},
  alldata: [],
  org_id: "",
  org_name: "",
  orglogin: false,
  user: {},
  org: {},
  org_details2: "",
  org_user: [],
  // userdetails:[]
  useremail: "",
  usermobile: "",
  usertype: "",
  username: "",
  all_status: [],
  all_priority: [],
  all_department: [],
  view_wallet_by_id: {},
  product: "666af12ba5c25924353013ed",
  // all_project_type:[],
  doc: "",
  // seconds: 30,
  // isRunning: false,
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case LOGIN:
      return state = {
        ...state,
        isHome: false,
        user_id: action.payload._id,
        type: action.payload.type,
        mobile: action.payload.mobile,
        name: action.payload.name,
        email: action.payload.email_id,
        profile_pic: action.payload.profile_pic,
        org_id: action.payload.org_id,
        token: action.payload.token,
        org_id: action.payload.org_id,
        org_name: action.payload.org_name,
        type: action.payload.type,
        product: action.payload.product,
        orgHome: true
      };

    case SWITCH:
      return state = {
        ...state,
        isHome: true,
        token: "",
        orgHome: false
      };
    case LOGOUT:
      return state = {
        ...state,
        isHome: true,
        user_id: "",
        type: "",
        mobile: "",
        name: "",
        email: "",
        token: "",
        orgHome: false
      };

    case SET_ALL_ORG:
      return state = { ...state, organizations: action.payload };
    case SET_ORG_USER_DETAILS:
      return state = {
        ...state,
        org_user: action.payload,
        // user_type: action.payload.type
      };

    case SET_USER_DETAILS:
      return state = { ...state, user_details: action.payload };
    case SET_ALL_DATA:
      return state = { ...state, alldata: action.payload };
    case SET_ORG_DETAILS2:
      return state = { ...state, org_details2: action.payload };
    case SET_ORG_DETAILS:
      return state = { ...state, org_details: action.payload };
    case SET_ORG_USER_DEATILS:
      return state = { ...state, org_user_details: action.payload };
    case SET_ORG_ID:
      return state = { ...state, org_id: action.payload };
    case SETLOGINORG:
      return state = { ...state, orglogin: action.payload };
    case SETMOBILE:
      return state = { ...state, otpmobile: action.payload };
    case SET_DOC:
      return state = { ...state, doc: action.payload };
    case SET_WALET_BY_ID:
      console.log("ghjdhdgsj", action.payload)
      return state = { ...state, view_wallet_by_id: action.payload };
    case SET_USER:
      return state = {
        ...state,
        useremail: action.payload.email,
        usermobile: action.payload.phone,
        usertype: action.payload.type,
        username: action.payload.user_name,

      };
    case SET_ORG:
      console.log("ooooooo", action.payload)
      return state = {
        ...state,
        org: action.payload,
        org_id: action.payload.org_id,
        type: action.payload.type,
        orgHome: true
      };
    // case FORGOT_PASSWORD:
    // return state = {
    //   ...state,
    //   isHome: false,
    //   email: action.payload.email,
    //   user_id: action.payload._id,
    //   type: action.payload.type,
    //   name: action.payload.name
    // };
    // case VERIFY_OTP:
    // return state = {
    //   ...state,
    //   isHome: false,
    //   email: action.payload.email,
    //   user_id: action.payload._id,
    //   type: action.payload.type,
    //   name: action.payload.name
    // };
    // case FORGOT_PASSWORD_PHONE:
    // return state = {
    //   ...state,
    //   isHome: false,
    //   email: action.payload.email,
    //   user_id: action.payload._id,
    //   type: action.payload.type,
    //   name: action.payload.name
    // };

    //timer
    // const timerReducer = (state = initial_state, action) => {
    //   switch (action.type) {
    //     case 'START_TIMER':
    //       return { ...state, isRunning: true };
    //     case 'STOP_TIMER':
    //       return { ...state, isRunning: false };
    //     case 'DECREMENT_TIMER':
    //       return { ...state, seconds: state.seconds - 1 };
    //     case 'RESET_TIMER':
    //       return { ...state, second: 30, isRunning: false };
    //     default:
    //       return state;
    //   }
    // }
    default:
      return state;
  }
}
