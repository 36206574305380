
export const all_admin_option = [
    { id: 0, name: "Dashboard", icon: "dashboard_customize ", link: "/" },
    { id: 1, name: "Campaign", icon: "campaign", link: "/Mail" },
    { id: 2, name: "Category", icon: "category", link: "/Category" },
    { id: 3, name: "Department", icon: "diversity_3", link: "/Department" },
    { id: 4, name: "Subscriber", icon: "sensor_occupied", link: "/Subscriber" },
    { id: 5, name: "Email Template", icon: "email", link: "/Template" },
    { id: 6, name: "Document Template", icon: "folder_open", link: "/Documenttemplate" },
    { id: 7, name: "Account setting", icon: "settings", link: "/AccountS" },
]

export const all_superadmin_option = [
    { id: 0, name: "Dashboard", icon: "dashboard_customize ", link: "/" },
    { id: 1, name: "Campaign", icon: "campaign", link: "/Mail" },
    { id: 2, name: "Category", icon: "category", link: "/Category" },
    { id: 3, name: "Department", icon: "diversity_3", link: "/Department" },
    { id: 4, name: "Subscriber", icon: "sensor_occupied", link: "/Subscriber" },
    { id: 5, name: "Users", icon: "group", link: "/Users" },
    { id: 5, name: "Email Template", icon: "email", link: "/Template" },
    { id: 6, name: "Document Template", icon: "folder_open", link: "/Documenttemplate" },
    { id: 7, name: "Account setting", icon: "settings", link: "/AccountS" },
    // { id: 8, name: "email", icon: "settings", link: "/Addtemplate" },
    // { id: 9, name: "document", icon: "settings", link: "/Documenttemplate" },

];


