// Define your GraphQL queries here
export const getDepartmentQuery = (orgId, page, rowPerPage) => {
  return {
    query: `
        query {
          departmentsPagination(org_id: "${orgId}", page: ${parseInt(page)}, rowPerPage: ${parseInt(rowPerPage)}) {
            totalDepartments
            departments {
              _id,
              name,
            }
          }
        }
      `,
    variables: {
      org_id: orgId,
      page: parseInt(page),
      rowPerPage: parseInt(rowPerPage),
    },
  };
};


export const departmentQuery = (orgId) => {
  return {
    query: `
        query {
            departments(org_id: "${orgId}") {
              _id,
              name,
            }
        }
      `,
    variables: {
      org_id: orgId,
    },
  };
};
