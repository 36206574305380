import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "../styles/style.scss";
import LoaderCon from "../../common/loader/containers/loader_cont";
import { TextField, Box } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
// import logo from "../../pages/Images/logo.png";
// import logo from "../../pages/Images/logo-2.png";
// import LMS from "../../pages/Images/LMS.png";
import LMS1 from "../../pages/image/EmailKaroLoginOnbaordingScreen.png";
import google from "../../pages/image/google.png";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import PhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import TextField from '@mui/material/TextField';
import 'react-phone-input-2/lib/style.css';
import { useParams } from 'react-router-dom';
import { Stack, CircularProgress } from '@mui/material';
import UNIVERSAL from "../../config/config";

// import Loader from '../../../common/loader/components/loader';

const defaultTheme = createTheme();

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      otp: "",
      change_num: false,
      enter_num: false,
      bt_type: true,
      time: {},
      seconds: 30,
    };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }
  resetTimer() {
    clearInterval(this.timer);
    this.setState({
      seconds: 30,
      time: this.secondsToTime(30),
    });
    this.timer = setInterval(this.countDown, 1000)
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    if (this.props.params.id === undefined) {
      console.log("called")
      const payload = {
        product: "666af12ba5c25924353013ed",
      };
      fetch(UNIVERSAL.SSO_URL + "api/v1/loginActivity/createLogin", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.statusCode === 201) {
            this.setState({
              data: data,
              isLoading: false,
              openErrorSnackbar: true,
              message: "Otp Verified",
              org: true,
              orgData: data.result,
            });
            window.location.href = "https://sso.technoboot.co/" + data.data._id
            // window.location.href = "http://localhost:3001/" + data.data._id

          }
          else {
            this.setState({
              data: data,
              isLoading: false,
              openErrorSnackbar: true,
              message: "Some Error Ocured"
            });
          }

        })
        .catch((error) => {
          console.error(error);
          this.setState({ openErrorSnackbar: true, message: "Soome Error Occured" })
        });
    }
    else {
      this.props.fetchToken(this.props.params.id, this.props.login.product);;
    }
    // You can start the timer here if OTP has been received from the backend
    // if (this.props.otpReceived) {
    //   this.startTimer();
    // }
  }

  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown = () => {
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    if (seconds === 0) {
      clearInterval(this.timer);
      // Enable the "Send OTP" button
      this.setState({ seconds: 30 });
    }
  }

  handleOnChange = (value, data, event, formattedValue, otp) => {
    this.setState({
      phone: value,
    });
    this.setState({ otp });
  }

  handlenumChange = (value) => {
    this.setState({ phone: value });
  }

  handleChange = (otp) => {
    this.setState({ otp });
  }
  // handleResendOTP = () => {
  //   console.log("Sending OTP for phone number:", this.state.contact_num);

  //   // Simulate an error here (replace with actual error handling)
  //   if (Math.random() < 0.5) {
  //     // Show an error message
  //     this.setState({ error: "Failed to send OTP. Please try again." });
  //     return;
  //   }

  //   // Dispatch your Redux action to resend OTP here
  //   this.props.phone_login(this.state.contact_num);

  //   // Clear the OTP input
  //   this.setState({ otp: '' });

  //   // Reset the timer
  //   this.resetTimer();

  //   // Start the timer and disable the "Send OTP" button
  //   this.startTimer();
  //   this.setState({ bt_type: false });

  //   // Disable phone number input
  //   this.setState({ phoneNumberDisabled: true });

  //   // Enable Enter OTP input
  //   this.setState({ enterOtpDisabled: false });

  //   // Clear the error
  //   this.setState({ error: null });
  // }
  //add
  handleRestart = () => {
    // Reset the state to the initial values
    this.setState({
      phone: "",
      otp: "",
      change_num: false,
      enter_num: false,
      bt_type: true,
      time: {},
      seconds: 30,
      phoneNumberDisabled: false,
      enterOtpDisabled: true,
      error: null,
    });
  }

  handleVerifyOTP = () => {
    console.log("handleVerifyOTP called");
    // Dispatch your Redux action to verify OTP here
    this.props.verify_otp(this.state.phone, this.state.otp);
  }

  render() {
    const { login } = this.props;

    if (login.user_id !== "") {
      return <Navigate to="/" />;
    }
    return (
      <Grid>
        <Stack sx={{ width: '100%', marginTop: "20%", justifyContent: 'center', alignItems: 'center' }} spacing={2}>
          <CircularProgress style={{ width: "100px", height: "100px" }} />
        </Stack>
      </Grid>
    )

    // return (
    //   <div>
    //     <ThemeProvider theme={defaultTheme}>
    //       <Grid container component="main" sx={{ height: '100vh' }}>
    //         <CssBaseline />

    //         <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
    //           {/* <img src={logo} style={{ marginTop: "3%", marginLeft: "8%" }} alt="Logo" /> */}
    //           <Box
    //             sx={{
    //               my: 8,
    //               mx: 4,
    //               p: 10,
    //               display: 'flex',
    //               flexDirection: 'column',
    //               alignItems: 'center',
    //               gap: "30px",
    //             }}
    //           >
    //             <Box component="form" noValidate sx={{ mt: 1 }}>
    //               <Typography style={{ fontSize: "30px", fontWeight: 500 }}>
    //                 Log in
    //               </Typography>
    //               <Typography style={{ fontSize: "15px", fontWeight: 500, width: "101%", marginTop: "10%" }}>
    //                 Phone Number
    //               </Typography>
    //               <PhoneInput

    //                 country={'in'}
    //                 // onChange={this.handleOnChange}
    //                 onChange={this.handlenumChange}
    //                 // value={this.state.value}
    //                 // style={{ marginLeft: "15%" }}
    //                 disabled={this.state.change_num}
    //                 style={{ width: "370px", marginTop: "3%", borderRadius: "10px" }}
    //                 inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', }}
    //               />
    //               {/* <TextField
    //                 style={{ width: "370px", marginTop: "3%", borderRadius: "10px" }}
    //                 id="outlined-basic"
    //                 placeholder="Phone number"
    //                 variant="outlined"
    //                 type="number"
    //                 onChange={(e) => this.handlenumChange}
    //                 disabled={this.state.change_num}
    //               /> */}

    //               {this.state.enter_num &&
    //                 <div style={{ display: "flex", justifyContent: "space-between", flexDirection: 'column' }}>
    //                   <Typography style={{ fontSize: "15px", fontWeight: 500, marginTop: "3%" }}>
    //                     Enter OTP
    //                   </Typography>
    //                   <TextField
    //                     style={{ width: "330px", borderRadius: "10px" }}
    //                     id="outlined-basic"
    //                     placeholder="Enter OTP"
    //                     variant="outlined"
    //                     value={this.state.otp}
    //                     onChange={(e) => this.handleChange(e.target.value)}
    //                     disabled={this.state.enterOtpDisabled}
    //                   />

    //                   <div style={{ display: "flex", }}>
    //                     {/* <Typography >
    //                       Didn’t get OTP */}
    //                     <Button
    //                       style={{ fontSize: "13px", marginTop: "10px", fontWeight: 500 }} onClick={(e) => {
    //                         this.resetTimer();
    //                         this.setState({
    //                           change_num: !this.state.change_num,
    //                           enter_num: !this.state.enter_num,
    //                           bt_type: !this.state.bt_type,

    //                         })
    //                       }}>
    //                       Didn’t get OTP
    //                       <strong style={{ color: "#7900FF", fontSize: '13px', marginLeft: "8px" }}>Change Number</strong>
    //                     </Button>
    //                     {/* </Typography> */}
    //                     <Button style={{ marginTop: "10px", }}
    //                       onClick={() => {
    //                         this.resetTimer();
    //                         this.props.phone_login(this.state.phone)
    //                       }
    //                       } disabled={this.state.time.s !== 0} >
    //                       <strong style={{ color: "rgba(173, 49, 19, 1)", fontSize: "12px", marginLeft: "3px" }}>Resend OTP <AccessTimeIcon style={{ height: "12px" }} /> <span> {this.state.time.s}</span></strong>
    //                     </Button>

    //                   </div>
    //                 </div>}

    //               {/* {this.state.error && (
    //                 <div>
    //                   <p style={{ color: "red" }}>{this.state.error}</p>
    //                   <Button onClick={this.handleRestart}>Restart</Button>
    //                 </div>
    //               )} */}

    //               <div className="login-button" style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "10%" }}>
    //                 <Button style={{ backgroundColor: "#000066", width: "300px", borderRadius: "10px", color: 'white' }} variant="contained"
    //                   // disabled={this.state.phone.length!==10}
    //                   disabled={this.state.phone.length < 11}
    //                   onClick={(e) => {
    //                     this.startTimer();
    //                     this.setState({
    //                       change_num: !this.state.change_num,
    //                       enter_num: !this.state.enter_num,
    //                       bt_type: !this.state.bt_type
    //                     })
    //                     if (this.state.bt_type) {
    //                       this.props.phone_login(this.state.phone)
    //                     }
    //                     if (!this.state.bt_type) {
    //                       this.props.verify_otp(this.state.phone, this.state.otp)
    //                     }
    //                   }}
    //                 >{this.state.bt_type ? "Send OTP" : "Verify OTP"}</Button>
    //               </div>

    //               {/* <Typography style={{ fontSize: "15px", marginTop: "6%", fontWeight: 500, textAlign: "center" }}>
    //                 or continue with
    //               </Typography>


    //               <Button variant="contained" style={{ backgroundColor: "#DCF5FF", color: "black", marginTop: "2%", textAlign: "center", width: "360px" }}
    //                 startIcon={<img
    //                   height={20}
    //                   src={google}
    //                   alt="logo_img"
    //                   style={{ display: "flex", flexDirection: "row" }}
    //                 // onClick={() => this.props.googleLogin()}
    //                 />}>
    //                 Sign in with Google

    //               </Button> */}
    //             </Box>
    //           </Box>
    //         </Grid>

    //         <Grid
    //           item
    //           xs={false}
    //           sm={4}
    //           md={7}
    //           sx={{
    //             backgroundImage: `url(${LMS1})`,
    //             backgroundRepeat: 'no-repeat',
    //             backgroundColor: (t) =>
    //               t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
    //             backgroundSize: 'cover',
    //             backgroundPosition: 'center',
    //             height: 'auto',
    //             width: '100%',
    //             borderRadius: '10px',
    //           }}
    //         />
    //       </Grid>
    //       {/* <Loader/> */}

    //     </ThemeProvider>
    //   </div>
    // );
  }
}




export default withParams(Login)





