import React, { Component } from "react";
import { connect } from "react-redux";
import Category from "../Components/Category";
import { close_snack_bar } from "../../../common/snackbar/action";
import {
    createCategory,
    editCategory,
    deleteCategory,
    viewAllCategory,
    searchCategory
} from "../Action";
import { viewDepartment } from '../../Department/Action'
export class CategoryContainer extends Component {
    render() {
        return (
            <Category {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        category:store.category,
        department:store.department,
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        createCategory: (orgId, category_name, category_dept, page, rowPerPage) => {
            dispatch(createCategory(orgId, category_name, category_dept, page, rowPerPage));
        },
        editCategory: (orgId, category_id, category_name, category_dept, page, rowPerPage) => {
            dispatch(editCategory(orgId, category_id, category_name, category_dept, page, rowPerPage));
        },
        deleteCategory: (orgId, categoryId, page, rowPerPage) => {
            dispatch(deleteCategory(orgId, categoryId, page, rowPerPage));
        },
        viewAllCategory: (orgId, page, rowPerPage) => {
            dispatch(viewAllCategory(orgId, page, rowPerPage));
        },
        searchCategory: (allCategory, e) => {
            dispatch(searchCategory(allCategory, e));
        },
        viewDepartment:(orgId) => {
            dispatch(viewDepartment(orgId));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer);