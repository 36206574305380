import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../components/Accountsetting";

import {
  addAccount,
  viewAccount,
  updateAccount,
  deleteAccount,

} from "../actions";
export class Controller extends Component {
  render() {
    return (
      <Login {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    mail: store.mail,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
 
    addAccount: (org_id, transpoter, email, passowrd, cc) => {
      dispatch( addAccount(org_id, transpoter, email, passowrd, cc));
    },
    updateAccount: (org_id, account_id, mail, transpoter, passowrd, cc) => {
      dispatch( updateAccount(org_id, account_id, mail, transpoter, passowrd, cc) );
    },
    deleteAccount: (org_id, account_id) => {
      dispatch( deleteAccount(org_id, account_id) );
    },
    viewAccount: (org_id) => {
      dispatch( viewAccount(org_id));
    },
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);