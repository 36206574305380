import React from "react";
import "./404.css"
import { Link } from "react-router-dom";

export default class Error extends React.Component {
    render() {
        return (
            <section class="page_404">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 ">
                            <div class="col-sm-10 col-sm-offset-1  ">
                                <div class="four_zero_four_bg">
                                    <center class="text-center ">404</center>

                                </div>

                                <center class="contant_box_404">
                                    <h3 class="h2">
                                        Look like you're lost
                                    </h3>

                                    <p>the page you are looking for not avaible!</p>
                                    <Link  class="link_404"
                                       to="/" style={{ textDecoration: "none", }}>Go to Home</Link>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}