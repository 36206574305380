import React, { Component } from "react";
import { connect } from "react-redux";
import SendEmail from "../Components/SendMail";
import { close_snack_bar } from "../../../common/snackbar/action";
import {
    viewAllOrgCategory,
} from "../../Category/Action";
import {
    viewAllCateTemplate,
    viewAllOrgTemplate
} from "../../Template/Action";
import {
    viewAllOrgAccounts,
} from "../../AccountSetting/Action";
import {
    createSendEmail,
    editSendEmail,
    deleteSendEmail,
    searchSendEmail,
    viewAllSendEmail,
    viewAllOrgSendEmail,
    createExcelSendEmail,
    sendCampaignIndividual,
    uploadDocument,
    sendCampaignCategory,
    sendCampaignBulk,
    setDocument
} from "./../Action";
import { viewDepartment } from '../../Department/Action'
export class SendEmailContainer extends Component {
    render() {
        return (
            <SendEmail {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        sendEmail: store.sendEmail,
        snackbar: store.snackbar,
        category: store.category,
        department: store.department,
        account: store.account,
        template: store.template

    };
};
export const mapDispatchToProps = dispatch => {
    return {

        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        viewAllOrgCategory: (orgId) => {
            dispatch(viewAllOrgCategory(orgId));
        },
        viewAllOrgAccounts: (orgId) => {
            dispatch(viewAllOrgAccounts(orgId));
        },
        viewAllOrgTemplate: (orgId) => {
            dispatch(viewAllOrgTemplate(orgId));
        },
        viewAllCateTemplate: (categoryId) => {
            dispatch(viewAllCateTemplate(categoryId));
        },
        viewAllOrgSendEmail: (orgId) => {
            dispatch(viewAllOrgSendEmail(orgId));
        },
        viewAllSendEmail: (orgId, page, rowPerPage, search) => {
            dispatch(viewAllSendEmail(orgId, page, rowPerPage, search));
        },
        createSendEmail: (orgId, name, email, phone, category,) => {
            dispatch(createSendEmail(orgId, name, email, phone, category,));
        },
        createExcelSendEmail: (orgId, category, data) => {
            dispatch(createExcelSendEmail(orgId, category, data));
        },
        editSendEmail: (orgId, senEmailId, name, email, phone, category, page, rowPerPage) => {
            dispatch(editSendEmail(orgId, senEmailId, name, email, phone, category, page, rowPerPage));
        },
        deleteSendEmail: (orgId, categoryId, page, rowPerPage) => {
            dispatch(deleteSendEmail(orgId, categoryId, page, rowPerPage));
        },
        searchSendEmail: (allSendEmail, e) => {
            dispatch(searchSendEmail(allSendEmail, e));
        },
        uploadDocument: (document) => {
            dispatch(uploadDocument(document));
        },
        setDocument: (url) => {
            dispatch(setDocument(url));
        },
        sendCampaignIndividual: (name, subject,
            email_type, reciver_id, sending_account_id,
            template, template_id, category, org_id, attachmentType, attachment) => {
            dispatch(sendCampaignIndividual(name, subject,
                email_type, reciver_id, sending_account_id,
                template, template_id, category, org_id, attachmentType, attachment));
        },
        sendCampaignCategory: (name, subject,
            email_type, reciver_id, sending_account_id,
            template, template_id, category, org_id, attachmentType, attachment) => {
            dispatch(sendCampaignCategory(name, subject,
                email_type, reciver_id, sending_account_id,
                template, template_id, category, org_id, attachmentType, attachment));
        },
        sendCampaignBulk: (name, subject,
            email_type, reciver_id, sending_account_id,
            template, template_id, category, org_id, attachmentType, attachment) => {
            dispatch(sendCampaignBulk(name, subject,
                email_type, reciver_id, sending_account_id,
                template, template_id, category, org_id, attachmentType, attachment));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SendEmailContainer);