import React, { Component } from 'react'
import {
    Grid,
    Typography,
} from "@mui/material";

export default class Summary extends Component {
    render() {
        return (
            <Grid>
                <Grid container justifyContent={'left'}>
                    <Typography className='topic'>Email</Typography>
                </Grid>
                <Grid container className='page'>
                    <Grid item xs={12} className='box2'>
                        <Typography className='topics'>Unlock Your Next Career Move With {''}</Typography>
                        <Grid container spacing={2} direction={'row'} className='box2'>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <Typography style={{ fontWeight: 'bold', color: '#433E39' }} >Name</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}
