// redux/reducers.js

import { 
    ALL_SENDEMAIL,
    SENDEMAIL_LENGTH,
    SEARCH_SENDEMAIL,
    DOCUMENT
  } from './Constant';
  
  const initialState = {
      allSendEmail: [],
     emaillength: '',
      searchSendEmail: [],
      url:"",
    };
  
    export default function reducer(state = initialState, action) {
      switch (action.type) {
      case ALL_SENDEMAIL:
        return {
          ...state,
          allSendEmail: action.payload, searchEmail: action.payload,
        };
      case SENDEMAIL_LENGTH:
        return {
          ...state,
         emaillength: action.payload
        };
      case SEARCH_SENDEMAIL:
        return {
          ...state,
          searchEmail: action.payload,
        };
      case DOCUMENT:
        console.log(action.payload)
        return {
          ...state,
          url: action.payload,
        };
      default:
        return state;
    }
  };
  