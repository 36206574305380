import React, { Component } from 'react'
import {
  Grid,
  Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress
} from "@mui/material";
import { Link } from "react-router-dom";
import SearchIcon from '../../image/search.png'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import Icon from "@mui/material/Icon";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import '../../../common/styles.scss';
import Snackbar from "../../../common/snackbar/components/snackbar"
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import '../../../common/introCss.scss';
import { debounce } from 'lodash';
import TabletMacOutlinedIcon from '@mui/icons-material/TabletMacOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined';

export default class EmailTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      searching: false,
      add: false,
      edit: false,
      view: false,
      delete: false,
      display: false,
      view: 'desktop',
      template_id: '',
      template_name: '',
      page: 0,
      rowsPerPage: 10,
      templateBody: '',
      isHTML: RegExp.prototype.test.bind(/(<([^>]+)>)/i),
    };
    this.debouncedViewAllSubscriber = debounce(this.props.viewAllTemplate, 500);

  }


  startIntro = () => {
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          intro: 'Welcome to Template!',
        },
        {
          element: '#tableCat', // Replace with the actual element ID or class
          intro: 'this is a list of Categories',
        },
        {
          element: '#addCat',
          intro: 'You can add your own template',
        },
        {
          element: '#editCat',
          intro: 'You can also make changes to particular template according to your needs',
        },
        {
          element: '#deleteCat',
          intro: 'You can delete unnecessary template',
        },
        {
          element: '#searchCat',
          intro: 'Search your template by name',
        },
      ],
    });
    intro.start();
    localStorage.setItem("email_intro", true)
  };

  componentDidMount() { 
    this.props.viewAllTemplate(this.props.login.org_id, this.state.page, this.state.rowsPerPage, "");
    if (!localStorage.getItem("email_intro")) {
      this.startIntro();
    }
  }

  // handleChangeRowsPerPage = (event) => {
  //   const newRowsPerPage = parseInt(event.target.value, 10);
  //   this.setState({ rowsPerPage: newRowsPerPage, page: 0 });
  //   this.props.viewAllDepartment(this.props.login.org_id, 0, newRowsPerPage);
  // };

  // handleChangePage = (event, newPage) => {
  //   this.setState({ page: newPage });
  //   // Fetch data with the new page value
  //   this.props.viewAllDepartment(this.props.login.org_id, newPage, this.state.rowsPerPage);
  // };



  render() {
    const { snackbar, close_snack_bar } = this.props
    const { templateBody, isHTML } = this.state
    const { view } = this.state;

    return (
      <Grid>
        <Grid container justifyContent={'left'}>
          <Typography className='topic'>Email Template</Typography>
        </Grid>
        <LoaderCon />
        <Grid container className='page'>
          <Grid item xs={12} className='box'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <FormControl fullWidth variant="standard" id='searchCat'>
                  <OutlinedInput
                    type={'text'}
                    className='textfield'
                    sx={{
                      '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                        boxShadow: '0px 2px 0px lightgray',
                      },
                      '.MuiOutlinedInput-notchedOutline': { border: '1px solid #E2E0DD' },
                      height: '48px',
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <LoadingButton
                            loading={this.props.loader.open}
                            loadingPosition="center"
                            variant="text"
                            onClick={() => {
                              this.setState({
                                add: true,
                              });
                            }}
                          >
                            {!this.props.loader.open && <img src={SearchIcon} alt="Search Template" style={{ font: '24px' }} />}
                          </LoadingButton>
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Search"
                    onChange={(event) => {
                      this.setState({ searching: event.target.value !== '' ? true : false });
                      this.debouncedViewAllSubscriber(this.props.login.org_id, this.state.page, this.state.rowsPerPage, event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={2}>
                <Link to="/AddTemplate">
                  <LoadingButton
                    loading={this.props.loader.progress}
                    loadingPosition='center'
                    id="addCat"
                    fullWidth
                    style={{ height: '48px' }}
                    className='add'
                    startIcon={<AddRoundedIcon />}
                    variant='contained'
                  >
                    <Typography>Template</Typography>
                  </LoadingButton>
                </Link>
              </Grid>
            </Grid>
            <Grid>
              <TableContainer id="tableCat" >
                <Table stickyHeader aria-label="sticky table" style={{ marginTop: '20px' }}>
                  <TableHead className="customTableHead">
                    <TableRow >
                      <TableCell justifyContent={'center'}>
                        <Typography className='headtext'>S No</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext'>Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext'>Category</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext' textAlign={'center'}>Action</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(this.props.template.searchTemp) && this.props.template.searchTemp.map((row, index) => {
                      return (
                        <TableRow hover tabIndex={-1}>
                          <TableCell justifyContent={'center'}>
                            <Typography className='celltext'>{index + 1}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className='celltext'>{row.temp_name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className='celltext'>{row.category_details?.name}</Typography>
                          </TableCell>
                          <TableCell>
                            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                              <Icon
                                id='editCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    edit: true,
                                    template_id: row._id,
                                    temp_body: row.temp_body,
                                  });
                                }}
                                style={{
                                  color: "#FFA741",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 231, 203, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "10%",
                                }}
                              >
                                visibility
                              </Icon>
                              <Icon
                                id='deleteCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    delete: true,
                                    template_id: row._id,
                                  });
                                }}
                                style={{
                                  color: "#C43232",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 234, 227, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "10%",
                                }}
                              >
                                delete
                              </Icon>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={this.props.template.templength}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
        {/*--------------------Add-------------------------*/}
        <Dialog
          open={this.state.add}
          maxWidth="xs"
          fullWidth
          sx={{
            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle className='titlespace'>
            <Grid container marginTop={1}>
              <Grid item xs={.5} />
              <Grid item>
                <Typography className='title'>Add Template</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={.5} />
              <Grid item xs={11} direction='column'>
                <TextField
                  variant='outlined'
                  type="text"
                  fullWidth
                  placeholder='Enter template'
                  className='textfield'
                  sx={{
                    '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                      boxShadow: '0px 2px 0px lightgray',
                    },
                  }}
                  onChange={(event) => {
                    this.setState({ template_name: event.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={.5} />
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%', marginTop: '3%' }} >
            <Button
              variant="outlined"
              className='cancel'
              onClick={() => {
                this.setState({ add: false });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className='submit'
              onClick={() => {
                this.setState({
                  add: false
                });
                // this.props.createDepartment(this.props.login.org_id, this.state.department_name, this.state.page, this.state.rowsPerPage);
              }}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog >
        {/*--------------------Edit-------------------------*/}
        <Dialog
          open={this.state.edit}
          maxWidth={this.state.view === 'desktop' ? 'md' : (this.state.view === 'tablet' ? 'md' : 'sm')} // Adjust the sizes as needed
          fullScreen
          sx={{
            backdropFilter: "blur(10px)",
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
        >
          <DialogContent>
            <Grid container >
              <Grid item xs={.2} />
              <Grid item xs={11.6} justifyContent={'center'}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="view-selector">
                    <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { this.setState({ view: 'desktop' }); }}>
                      <DesktopMacOutlinedIcon />
                    </button>
                    <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => this.setState({ view: 'tablet' })}>
                      <TabletMacOutlinedIcon />
                    </button>
                    <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { this.setState({ view: 'mobile' }); }}>
                      <PhoneAndroidOutlinedIcon />
                    </button>
                  </div>
                  <Icon
                    class="material-symbols-outlined"
                    onClick={() => {
                      this.setState({
                        edit: false
                      });
                    }}
                    style={{
                      color: "rgb(43, 63, 84)",
                      cursor: "pointer",
                      padding: '8px',
                      fontSize: '22px',
                    }}
                  >
                    close
                  </Icon>
                </Grid>

                <iframe
                  className={`container ${view}`}
                  title="External Page"
                  allowFullScreen
                  srcDoc={isHTML(this.state.temp_body) ? this.state.temp_body : null}
                  style={{ border: 'none' }}
                />
              </Grid>
              <Grid item xs={.2} />
            </Grid>
          </DialogContent>
        </Dialog >
        {/*--------------------Delete-------------------------*/}
        <Dialog
          open={this.state.delete}
          maxWidth="xs"
          fullWidth
          sx={{
            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle className='titlespace'>
            <Grid container marginTop={1}>
              <Grid item xs={.5} />
              <Grid item>
                <Typography className='title'>Delete Template</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={.5} />
              <Grid item xs={11} direction='column'>
                <Typography style={{ fontWeight: 'bold' }} >
                  Are you sure do you want to delete this template?
                </Typography>
              </Grid>
              <Grid item xs={.5} />
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%', }} >
            <Button
              variant="outlined"
              className='cancel'
              onClick={() => {
                this.setState({ delete: false });
              }}
            >
              No, Cancel it.
            </Button>
            <Button
              variant="contained"
              className='submit'
              onClick={() => {
                this.setState({
                  delete: false
                });
                this.props.deleteTemplate(this.props.login.org_id, this.state.template_id, this.state.page, this.state.rowsPerPage);
              }}
            >
              Yes, Delete it.
            </Button>
          </DialogActions>
        </Dialog >
      </Grid>
    )
  }
}
