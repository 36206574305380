// Define your GraphQL queries here
export const getAccountsQuery = (org_id, page, rowPerPage) => {
  return {
    query: `
        query {
          accountsPagination(org_id: "${org_id}", page: ${parseInt(page)}, rowPerPage: ${parseInt(rowPerPage)}) {
            totalAccounts
            accounts {
              _id,
              mail_transporter,
              email,
              password,
              cc,
            
            }
          }
        }      
      `,
    variables: {
      org_id: org_id,
      page: parseInt(page),
      rowPerPage: parseInt(rowPerPage),
    },
  };
};
export const getOrgAccountsQuery = (org_id) => {
  return {
    query: `
        query {
          accounts(org_id: "${org_id}") {
            _id
            email
          }
        }      
      `,
    variables: {
      org_id: org_id,
    },
  };
};
